.numHeaderValue {
    font-size: 60px;
}

.numHeader {
    margin: 50px;
}

.freqTable {
    margin-bottom: 20px;
}

.recencyBullet {
    color: #6C757D;
}

.listIndent {
    margin-left: 15px;
}
