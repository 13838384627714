.tosWindow{
    margin: 0% 20%;
}
.tosTitle{
    margin: 0% 20%;
}
.tosHeader{
    font-size: 14pt;
    font-weight: 600;
    margin: 2% 0% 1%;
}
.tosContent{
    white-space: pre-line;
}
