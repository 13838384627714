@font-face{
  font-family:'archivo-narrow-bold';
  src: url(../../assets/fonts/ArchivoNarrow-Bold.ttf);
  font-style:normal;
}
@font-face{
  font-family:'archivo-narrow-regular';
  src: url(../../assets/fonts/ArchivoNarrow-Regular.ttf);
  font-style:normal;
}
@font-face{
  font-family:'lato-regular';
  src: url(../../assets/fonts/Lato-Regular.ttf);
  font-style:normal;
}
@font-face{
  font-family:'lato-bold';
  src: url(../../assets/fonts/Lato-Bold.ttf);
  font-style:normal;
}
@font-face{
  font-family:'lato-italic';
  src: url(../../assets/fonts/Lato-Italic.ttf);
  font-style:normal;
}
@font-face{
  font-family:'gotham-medium';
  src: url(../../assets/fonts/Gotham-Medium.otf);
  font-style:normal;
}
@font-face{
  font-family:'gotham-bold';
  src: url(../../assets/fonts/GothamBold.ttf);
  font-style:normal;
}
@font-face{
  font-family:'gotham-light';
  src: url(../../assets/fonts/GothamLight.ttf);
  font-style:normal;
}
@font-face{
  font-family:'gotham-book';
  src: url(../../assets/fonts/GothamBook.ttf);
  font-style:normal;
}
@font-face {
  font-family: "gotham";
  src: url('../../assets/fonts/Gotham-Book.woff') format('woff'),
       url('../../assets/fonts/Gotham-Book.woff2') format('woff2'),
       url('../../assets/fonts/Gotham-Book.ttf') format('truetype');
  font-weight: normal;
}
h1{
  font-family:'archivo-narrow-bold';
  font-weight: 300;
}
p{
  font-family:'lato-regular';
  font-weight: 100;
}

.app {
    background-color: #fff !important;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    width: 100%;
}

.topBlock{
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 5%;
}
.appLogo {
  height: 160px;
  float: left;
  /*padding: 12%;*/
  object-fit: contain;
}

.leafbg {
  background-image: url(../../assets/coffeepass_web_leaf_home.png);
  position: absolute;
  /*top: 27px;*/
  left: -30px;
  top: -10px;
  /*width: 250px;
  height: 225px;
  background-size: 250px 225px;*/
  width: 200px;
  height: 175px;
  background-size: 200px 175px;
}
.leafbg-mobile {
  background-image: url(../../assets/coffeepass_web_leaf_home.png);
  position: absolute;
  left: -30px;
  top: -10px;
  width: 130px;
  height: 115px;
  background-size: 130px 115px;
}

.tag {
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  font-family: gotham-book;
}

.mt30 {
  margin-top: 10px;
}
.mt30 a {
  margin-left: 10px;
}

.mob_cont {
  float: left;
  width: 40%;
  margin-top: 55px;
}

.logo {
  width: 250px;
  height: 250px;
}
.logo-mobile {
  width: 180px;
  height: 180px;
}

.logoInte {
  width: 142px;
  float: left;
  line-height: 161px;
  margin: 10px;
}

.heading {
    margin-top: 45px;
    font-size: 32px;
    font-weight: 200;
    line-height: 40px;
    font-family: gotham;
    color: #333333;
}
.heading-mobile {
    margin-top: 15px;
    font-size: 15px;
    font-weight: 200;
    /*line-height: 40px;*/
    font-family: gotham;
    color: #333333;
}
.heading span {
    color: #c25551;
    font-family: gotham-bold;
}
.heading-mobile span {
    color: #c25551;
    font-family: gotham-bold;
}

.divider {
  margin: 10px auto 30px;
  width: 60px;
  height: 5px;
  background-color: #c25551;
}

.stripes {
    transform: skewY(-6deg);
    transform-origin: 0;
    height: 729px;
    width: 100%;
    position: absolute;
}

.introBlock {
    position: relative;
    left: 0px;
    top: 0px;
  }

.stripes .topStripe {
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: linear-gradient(45deg, #F5F5F5 0%, #F5F5F5
    100%) !important;
}

.headerBlock {
  margin-bottom: 3%;
  padding-left:10%;
  padding-right:10%;
  color: white;
  display:flex;
  z-index: 1;
  width: 100%;
}

.hamburgerMenu {
  margin-top: 10%;
  background-color: #333333;
  width: 50px;
  height: 50px;
}


.hamburgerMenuLinks {
  color: #5B6F9C;
  margin-bottom: auto;
  margin-top: auto;
  padding-top: 10%;
  padding-bottom: 10%;
  padding-left: 5%;
  letter-spacing: 2px;
  transition: all ease .3s;
  font-family: 'archivo-narrow-bold';
  font-size: 1.4em;
}

.hamburgerMenuLinks:hover {
  color: rgb(29, 49, 97);
  letter-spacing: 3px;
}

.customerBlock {
  padding-left:10%;
  padding-right:10%;
  color: white;
  display: grid;
  grid-template-areas: 'appStoreLogoContainer imagesContainer';
  grid-template-columns: 60% 40%;
}
.appStoreLogoContainer {
  flex:1;
  padding: 5%;
  margin-top: -175px;
  text-align: center;

}
.appStoreLogoContainer-mobile {
  -ms-flex: 1 1;
  flex: 1 1;
  /*padding: 5%;*/
  margin-top: -65px;
  text-align: center;
}

.tagline {
  font-family: 'gotham-light';
  font-size: 40px;
  width: 75%;
}
.tagline span {
  font-family: 'gotham-bold';
}

.line_bot {
  width: 62px;
  background-color: #c25451;
  height: 6px;
  float: left;
  clear: both;

  margin-top: 6px;
}

.headerWrapper {
  position: relative;
  width: 100%;
}

.headerTextWrapper {
    display: flex;
    justify-content: center;
    padding-top: 8%;
    padding-bottom: 5%;
}

.headerText {
    font-size: 50px;
    justify-content: center;
    color: #333333;
    margin-left: 5%;
}
.workWithBlock {
  height: auto !important;
}


.description{
  font-size:20px;
  padding-right: 40%;
}
.imagesContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.imagesContainer > * {
    position: absolute;
  }

.screenShot{
  height: 636px;
}

.iphoneMockup {
    height: 730px;
}

.shopParent {
  position: relative;
}

.shopBlock{
  padding-left:10%;
  padding-right:10%;
  display:flex;
  flex-direction: row;
  padding-top:5%;
  padding-bottom:5%;
  justify-content: center;
}

.ownAShop{
  padding-right: 5%;
}
.partnerWithMojoButton{
  font-family:'archivo-narrow-bold';
  font-weight: 300;
  color:#ffffff;
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
  font-size: 20px;
  font-weight: 200;
  border-radius: 10px;
  padding:20px;
  border-width: 0;
  text-shadow: 2px 2px #91b1e4;
}
.partnerWithMojoButton:hover{
  background: #59719D;
}
.partnersBlock{
  padding-left:10%;
  padding-right:10%;
  background-image: linear-gradient(45deg, #5B6F9C 0%, #65CADC,  100%);
  color: white;
  padding-top:5%;
  padding-bottom:5%;
}
.partnersLabel{
  padding-left:5%;
}
.logoContainer{
  padding-top:5%;
  padding-left:5%;
  padding-right:5%;
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
}
.partnerLogo{
  height: 200px;
  width: 250px;
  object-fit: contain;
  padding: 3%;
}

.navBar {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: 'row';
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 50px;
  margin-top: -30px;
}

.navBar a {
  color: #303030;
  text-decoration: none;
  margin-right: 15px;
}

.navButton{
  font-family: 'gotham-medium';
  font-size: 14px;
  padding-bottom: 30px;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 75px;
  margin-bottom: auto;
  letter-spacing: 2px;
  transition: all 0.3s ease;
  height: auto;
  text-decoration: none;
  margin-right: 15px;
}




.navButton:hover{
  color: rgb(80, 80, 80);
  border-color: rgb(80, 80, 80);
  cursor: pointer;
  letter-spacing: 3px;
  transition: all 0.5s ease;
  text-decoration: none;
}


.appStoreLogo{
  height: 200px;
  width: 250px;
  object-fit: contain;
  padding:5%;
}


.analytics{
  display:flex;
  flex-direction: column;
  align-items: center;
}
.analyticsInputs{
  display:flex;
  flex-direction: row;
  margin-left: 10px;
}

.middleStripe {
  width: 100%;
  height: 100%;
  transform: skewY(-6deg);
  transform-origin: 0;
  position: relative;
}

.stripes span {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  background: white;
}

.HowItWorksWrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
  overflow-x: hidden;
}

.genericSource{
  font-family: 'lato-italic';
  font-size: 0.8em;
  letter-spacing: 3px;
}
.genericAuthor{
  font-family:'lato-bold';
  font-size: 0.8em;
  letter-spacing: 2px;
}

.stepGenericImage {
  grid-area: stepGenericImage;
  z-index: inherit;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.shopGenericImage {
    grid-area: stepGenericImage;
    z-index: inherit;
    position: relative;
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    max-height: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }

.genericTitle {
  font-size: 150%;
}

.stepGenericText {
  grid-area: stepGenericText;
  font-size: 150%;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.stepGenericBody {
  justify-self: start;
}

.genericBlock {
  position: relative;
  margin-bottom: 5%;
  /*margin-top: 5%;*/
}

.stepBlock {
  height: 25%;
}

.workBlock {
  height: 25% !important;
  max-height: 500px;
}
.ownerBlock{
  margin-bottom: 10% !important;
}

.extraPad {
    padding-top: 40px;
    padding-bottom: 40px;
}

.step3Block::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    transform: skewY(-6deg);
    background-color: #F8F9FA;
}

.step3Block {
    z-index: 2;
}

.genericBlockReverse {
  position: relative;
  padding: 0.5%;
  margin-bottom: 5%;
  /*margin-top: 2.5%;*/
  margin-left: auto;
  margin-right: auto;
  flex-direction: row-reverse;
}

.stepByStepIcon {
  max-width:  100%; /*or 70%, or what you want*/
  max-height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 3;
}

.middleBlock {
  margin-bottom: 3%;
  height: 275%;
}

.endBlock {
position: relative;
}

.endBlock::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 90%;
  z-index: 1;
  top: 0;
  transform: skewY(-6deg);
  background-color: #F8F9FA;
}

.endBlock .testimonWrapper {
  position: relative;
  width: 100%;
  padding-top: 8%;
  padding-left: 8%;
  padding-right: 8%;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  z-index: 2;
}

.testimonTitle {
  width: 100%;
  text-align: center;
  font-size: 2.5em;
}

.testimonDeck {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}


.testimonCard {
  margin: 3%;
  border-radius: 2px;
  width: 20%;
  min-width: 225px;
}

.cardImage {
  margin-left: auto;
  margin-right: auto;
  height: 160px;
}

.cardTextBox {
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.15);
  background-color: white;
  padding: 5%;
  margin: 5% 0px 0px 0px;
  font-size: 1.5em;
}

.cardTextTitle {
  font-family: 'archivo-narrow-bold';
  font-size: 1.3em;
  margin-bottom: 2%;
}

.cardTextBody {
  font-family: 'lato-regular';
  font-size: .8em;
}

.cardWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 5%;
}

.reasonCardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 27%;
  min-width: 200px;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 10%;
}

.testimonAuthor {
  font-style: italic;
  font-size: 1em;
}

.footerWrapper{
  border-top: 2px solid rgba(0,0,0,.15);
  width: 100%;
  position: relative;
  z-index: 2;
  background-color: #F8F9FA;
}

.footerSpacer {
  padding-top: 3%;
  padding-bottom: 1%;
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.numberCircle {
    position: absolute;
    margin-top: -50px;
    margin-left: -70px;
    border-radius: 50%;
    /* behavior: url(PIE.htc); */
    /* remove if you don't care about IE8 */
    width: 55px;
    height: 55px;
    padding: 8px;
    background: #c25551;
    border: 2px solid #ffffff;
    color: #ffffff;
    text-align: center;
    font: 32px Arial, sans-serif;
}

.copyrightFooter {
  display: flex;
  flex-direction: row;
  opacity: 0.4;
}

.copyrightFooterIcon {
  height: 1em;
}

.copyrightFooter p {
  align-self: flex-end;
  margin-bottom: 0px;
  font-size: 1em;
}

.allLinksWrapper{
  display: flex;
  flex-direction: row;
  padding-bottom: 3%;

}

.linksWrapper {
  margin: 0px -10% 0px 10%;
  padding: 0px 10% 0px 0%;
}

.linksTitle {
  font-style: bold;
  font-size: 1.2em;
  opacity: 0.8;
}

.footerLink {
  font-size:  1em;
  opacity: 0.8;
  color: black;
}

.footerLink:hover {
  opacity: 1.0;
  cursor: pointer;
}

.endBlock .partnersBlock {
  background-color: rgba(0,0,0,0);
  z-index: 2;
  position: relative;
  padding-top: 10%;
}

.endBlock .partnersBlock h1 {
  color: black;
  font-size: 2em;
}


/**Shop Landing Page **/
.shopHead {
  position: relative;
  height: 550px;
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: -3%; /*Can't figure out why a space is between the divs that this fixes*/
}

.shopTopBlock {
  position: relative;
  display: inline-block;
  width: 100%;
}

.shopHeadImageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  max-height: 100%;
  padding-left: 0px;
}

.shopHeadImageContainer img {
  mask-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,1), rgba(0,0,0,0));
}

.shopHeadImage {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}

.shopHeadButtonContainer {
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
}

.shopHeadButton {
  font-family: 'gotham-medium';
  margin-left: 13%;
  margin-right: auto;
  font-size: 20px;
  border-radius: 3px;
  color: white;
  background-color: #78A852;
  transition: all 250ms ease;
  padding: 15px 20px;
  letter-spacing: 1px;
}

.shopHeadButton:hover {
  background-color: rgba(121, 168, 82, 0.699);
  color: white
}

.navBarBackground {
  width: 100%;
  height: auto;
  margin: 0px;
  box-shadow: 0 2px 20px grey;

}

.shopHeadTextContainer {
  margin-top: 7%;
  margin-bottom: auto;
}

.shopHeadTextContainer .tagline {
  margin-left: auto;
  margin-right: auto;
}

.shopMidBlock {
  height: 100%;
  position: relative;
  width: 100%;
  margin-top: -6px;
}

.whyMojoWrapper{
  background-color: #F8F9FA;
}

.shopHead svg {
  position: absolute;
  bottom: 0px;
}

.wedge {
  fill: #F8F9FA;
  stroke: #F8F9FA;
  stroke-width: 1;
  z-index: 2;
  display: inline;
}

.blockWrapper {
  position: relative;
  height: auto;
  overflow-x: hidden;
  overflow-y: hidden;
}

.caseStudy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 350px;

  margin-left: auto;
  margin-right: auto;
  height: 95%;
  padding: 15px;
  background-image: url(../../assets/shopLanding/graph_button.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.15);
  border-radius: 10px;
}

.caseStudy:hover {
    -webkit-box-shadow: 0 0 16px rgba(102, 102, 102, 0.5);
    -moz-box-shadow: 0 0 16px rgba(102, 102, 102, 0.5);
    -o-box-shadow: 0 0 16px rgba(102, 102, 102, 0.5);
    box-shadow: 0 0 16px rgba(102, 102, 102, 0.5);
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    cursor: pointer;
}

.caseStudyText {
  margin-left: auto;
  margin-right: auto;
  font-family: 'archivo-narrow-bold';
  transition: all 550ms ease;
  color: #56719e;
  font-size: 1.5em;
  letter-spacing: 3px;
  text-align: center;
  transition: all 1s ease;
}
.caseStudy:hover .caseStudyText{
  letter-spacing: 5px;
}
.moneyStats {
  margin-bottom: 5%;
  padding-top: 2%;
}
.offWhiteBackground {
  background-color: #F8F9FA;
}
.workIcon {
  max-width:  100%; /*or 70%, or what you want*/
  max-height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 3;
}

.teamsView {
  display: flex;
  flex-direction: row;
  margin-top: 5%;
  margin-bottom: 2.5%;
  margin-left: 5%;
  margin-right: 5%;
  flex-wrap: wrap;
  justify-content: space-evenly;

}

.serviceCard {
  margin-left: 10% !important;
  margin-right: 10% !important;
}

.teamCard {
  width: 25%;
  min-width: 300px;
}

.aboutUsWrapper {
  background-color: #F8F9FA;
}

.onboardingTOSLink {
  font-size:  1em;
  opacity: 0.8;
  color: blue;
  text-decoration: underline;
}

@media (max-width: 1366px) {
  .workWithBlock {
    height: auto !important;
  }
  .workBlock{
    max-height: 300px;
  }
}
@media (max-width: 1024px) {
  .HowItWorksWrapper {
    height: 1400px;
  }
  .middleBlock {
    height: auto;
  }
  .workWithBlock {
    height: auto !important;
  }
  .workBlock{
    max-height: 300px;
  }
  .caseStudy {
    width: 290px;
  }
  .caseStudy {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    padding: 15px;
    background-image: url(../../assets/shopLanding/graph_button.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.15);
    border-radius: 10px;
  }
}

@media (max-width : 694px) {
  .appStoreLogo {
    margin-top: -10%;
    margin-bottom: -10%;
  }
    .iphoneMockup {
        margin-top: 5%;
    }
    .screenShot {
        margin-top: 5%;
    }
}

@media (max-width : 850px) {
  .customerBlock {
    grid-template-areas:
      'appStoreLogoContainer'
      'imagesContainer';
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
  }
  .imagesContainer {
    height: 730px;
    padding-bottom: 20%;
  }
  .partnerLogo{
    height: 100px;
    width: 175px;
    object-fit: contain;
    padding: 3%;
  }
  .headerTextWrapper {
    display: flex;
    justify-content: center;
    padding-top: 8%;
    padding-bottom: 15%;
}

    .numberCircle {
        position: absolute;
        margin-top: -50px;
        margin-left: -20px;
        border-radius: 50%;
        /* behavior: url(PIE.htc); */
        /* remove if you don't care about IE8 */
        width: 40px;
        height: 40px;
        padding: 8px;
        background: #c25551;
        border: 2px solid #ffffff;
        color: #ffffff;
        text-align: center;
        font: 20px Arial, sans-serif;
    }
}

@media (max-width : 767px) {
  .caseStudy{
    width: 290px;
    height: 405px;
  }
  .stepGenericBlock {
    padding-top: 10%;
  }
  .stepGenericBlockReverse {
    padding-top: 10%;
  }
  .stepGenericImage {
    margin-top: 0px;
    max-height: 75%;
  }
  .workImage {
    max-height: 200px;
  }
  .workBlock {
    max-height: 700px;
    margin-bottom: 70px;
    padding-top: 10px;
  }
  .ownerTestimonText {
    margin-bottom: 5% !important;
  }
  .tagline {
    font-size: 30px;
    width: 100%;
  }
}
.contactWedge{
  margin-bottom: -5px;
  z-index: -1;
}
.emailWindow {
  margin-bottom: -170px;

}
.blockTitle {
  font-family: archivo-narrow-bold;
  font-size: 2em;
  letter-spacing: 2px;
}
@media (max-width : 550px) {
  .HowItWorksWrapper {
    height: 1800px;
  }
/*  .appLogo {
    height: 100px;
  }*/
  .description {
    padding-right: 0px;
  }
  .iphoneMockup {
    margin-top: 5%;
  }
  .tagline {
    font-size: 30px;
    width: 100%;
  }
  .copyrightFooter {
    padding-top: 5%;
  }
  .headerText {
    font-size: 38px;
    justify-content: center;
    text-align: center;
    color: #56719e;
    margin-left: 10%;
    margin-right: 10%;
  }
  .reasonCardWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    min-width: 300px;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 10%;
  }
}

@media (max-width : 400px) {
  .customerBlock {
    grid-template-rows: 30% 50%;
  }
  .appStoreLogo {
    margin-top: -20%;
    margin-bottom: -20%;
  }
  .headerBlock{
    justify-content: center;
  }
  .navBar {
    justify-self: flex-end;
  }
  .customerBlock{
    text-align: center;
  }
  .description{
    display:none;
  }
  .partnersLabel{
    text-align: center;
  }
  .stepGenericText {
    margin-top: 5%;
    margin-right: 0px;
  }
}

.tos-onboarding {
  flex-direction: column;
}

.background-white {
  background-color: #ffffff;
}
